<template>
    <div>
        <div class="leisure_buttons_group">

<!--            <router-link tag="button" class="btn_minigame_comp bw3"-->
<!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_EOS}"-->
<!--                         :to="{path:'/leisure/eospowerball1m', query: {t: new Date().getTime()}}">-->
<!--                EOS파워볼-->
<!--            </router-link>-->

            <router-link tag="button" class="btn_minigame_comp bw3"
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BET365}"
                         :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}">
                BET365
            </router-link>
            <router-link tag="button" class="btn_minigame_comp bw3"
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"
                         :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">
                보스코어
            </router-link>
          <router-link tag="button" class="btn_minigame_comp bw3"
                       :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SKY}"
                       :to="{path:'/leisure/sky_speed_bakara', query: {t: new Date().getTime()}}">
            스카이파크
          </router-link>

        </div>

        <div class="leisure_buttons_group">
            <router-link tag="button" class="btn_minigame_comp bw4"
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SPEEDGAME}"
                         :to="{path:'/leisure/speedgamepdali', query: {t: new Date().getTime()}}">
                게임스타
            </router-link>
            <router-link tag="button" class="btn_minigame_comp bw4"
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_JWCASINO}"
                         :to="{path:'/leisure/jwcasinoangel', query: {t: new Date().getTime()}}">
                JW게임
            </router-link>
            <router-link tag="button" class="btn_minigame_comp bw4"
                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_CANDY}"
                         :to="{path:'/leisure/candy_dragontiger', query: {t: new Date().getTime()}}">
                용호게임
            </router-link>
          <router-link tag="button" class="btn_minigame_comp bw4"
                       :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_ROYAL}"
                       :to="{path:'/leisure/royalsutda', query: {t: new Date().getTime()}}">
            로얄섯다
          </router-link>
        </div>


    </div>

</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureGameCompLinks",
        data() {
            return {
                leisureConst: leisureConst,
            }
        },
        methods: {
            commingsoon() {
                alert('준비중입니다')
            },
        }
    }
</script>

<style scoped>

</style>