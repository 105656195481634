import { render, staticRenderFns } from "./LeisureCompTgames365GameLinks.vue?vue&type=template&id=0b814667&scoped=true&"
import script from "./LeisureCompTgames365GameLinks.vue?vue&type=script&lang=js&"
export * from "./LeisureCompTgames365GameLinks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b814667",
  null
  
)

export default component.exports