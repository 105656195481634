<template>

        <div class="leisure_button_group_sub">
            <router-link tag="button" class="btn03 bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SUTDA}"
                         :to="{path:'/leisure/sutda', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 섯다</router-link>

            <router-link tag="button" class="btn03 bw2"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_NINEBALL}"
                         :to="{path:'/leisure/nineball', query: {t: new Date().getTime()}}"><i class="fa fa-coffee"></i> 나인볼</router-link>

        </div>


</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureCompCrownGameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>